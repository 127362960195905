import { Navigate, Route, Routes } from 'react-router-dom'
import help from 'src/pages/help/main'
import LoginForm from 'src/pages/login/LoginForm'
import metal_flow from 'src/pages/metal_flow/main'
import Archive from 'src/pages/orders/archive/main'
import Production from 'src/pages/orders/production/main'
import RecentOrders from 'src/pages/orders/recently/main'
import Registration from 'src/pages/orders/registration/main'
import Report from 'src/pages/orders/report/main'
import { RouteConfig } from 'src/types/global'
import { AppRoutes } from '../lib/routes'
import Attendance from '../pages/attendance/main'
import order_detail from '../pages/order-detail/main'
import reclamations from '../pages/reclamation/main'
import settings from '../pages/settings/main'
import RequireAuth from './PrivateRoute'

const protectedRoutes: RouteConfig[] = [
  ...Production,
  ...Registration,
  ...RecentOrders,
  ...Archive,
  ...Report,
  ...Attendance,
  ...order_detail,
  ...reclamations,
  ...settings,
  ...metal_flow,
  ...help
]

export default function AppRouter() {
  return (
    <Routes>
      {/* Public route for login */}
      <Route path="/login" element={<LoginForm />} />

      {/* Default route: redirects to orders/production */}
      <Route path="/" element={<Navigate to={AppRoutes.help} />} />

      {/* Protected routes requiring authentication */}
      {protectedRoutes.map(route => (
        <Route
          key={route.path}
          path={route.path}
          element={<RequireAuth>{route.element}</RequireAuth>}
        />
      ))}
    </Routes>
  )
}
