import { UilArrowLeft, UilCalculatorAlt } from '@iconscout/react-unicons'

import {
  Autocomplete,
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { Link, NavigateOptions, To, useNavigate, useSearchParams } from 'react-router-dom'
import { PageTitle } from 'src/components/PageTitle'
import { useGetAllMaterialCategoriesQuery, useGetMaterialsQuery } from 'src/types/graphql-shema'
import { Action } from './actions'

export const units = ['кг', 'мм', 'см', 'м', 'м^2', 'm^3', 'грамм', 'штуки', 'литры']

export const SectionTitle = () => <PageTitle title="ПДО" icon={UilCalculatorAlt} p={1}></PageTitle>

type GoBackBtnProps = { to?: To; options?: NavigateOptions; delta?: number }
export const GoBackBtn = (props: GoBackBtnProps) => {
  const navigate = useNavigate()
  return (
    <Box>
      <IconButton
        onClick={() => {
          if (props.delta) {
            navigate(props.delta!)
          } else {
            if (!props.to) {
              throw Error('GoBackBtn props "to" were not passed')
            }
            navigate(props.to!, props.options)
          }
        }}
      >
        <UilArrowLeft />
      </IconButton>
    </Box>
  )
}

interface FormProps {
  header: string
  goBackUrl?: string
  goBackDelta?: number
  children: any
  beforeFormComp?: any
}

export function GenericForm(props: FormProps) {
  return (
    <Container maxWidth="xs">
      <Stack direction="row" alignItems={'center'} p={1} gap={1}>
        <GoBackBtn to={props.goBackUrl} delta={props.goBackDelta} />
        <Typography variant="h6" color={'var(--accent)'}>
          {props.header}
        </Typography>
      </Stack>

      <Stack gap={2} my={2}>
        {props.beforeFormComp}
        {props.children}
      </Stack>
    </Container>
  )
}

export function MySelect(props: {
  value?: any
  defaultValue?: any
  label: string
  selectElements: Array<{ name: string; value: any }>
  onChange: (e: SelectChangeEvent<any>) => void
}) {
  return (
    <FormControl>
      <InputLabel>{props.label}</InputLabel>
      <Select
        value={props.value}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        label={props.label}
      >
        {props.selectElements.map(unit => (
          <MenuItem key={unit.name} value={unit.value}>
            {unit.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export type Material = {
  label: string
  id: number
  unit: string
  current_qty: number
}

export function MaterialSelect(props: { setMaterial: (m: Material) => void; material?: Material }) {
  const { setMaterial, material } = props
  const [searchParams] = useSearchParams()
  const materialid = searchParams.get('material_id')

  const materials = useGetMaterialsQuery({
    onCompleted: data => {
      if (materialid) {
        const m = data?.metal_pdo_materials.find(each => each.id === +materialid)
        if (!m) {
          throw Error(`material with this id not found`)
        }

        setMaterial({
          id: m.id,
          label: m.name,
          unit: m.unit,
          current_qty: m.stock?.current_qty
        })
      }
    }
  })

  return (
    <>
      {!materialid ? (
        <Autocomplete
          disablePortal
          onChange={(e: any, value) => {
            if (value) {
              setMaterial(value)
            }
          }}
          getOptionLabel={option => option.label}
          options={
            materials.data?.metal_pdo_materials.map(
              e => ({ label: e.name, id: e.id, unit: e.unit } as Material)
            ) || []
          }
          renderInput={params => <TextField {...params} label="Метериал" />}
        />
      ) : (
        <Box>
          <Typography>Материал: {material?.label}</Typography>
          <Typography>Текущий остаток: {material?.current_qty}</Typography>
        </Box>
      )}
    </>
  )
}

export const QtyInputWithUnit = (props: {
  unit?: string
  value?: string
  setValue: (num: string) => void
}) => {
  const { unit = '', value: qty, setValue: setQty } = props
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <TextField
        type="number"
        autoComplete="off"
        label="кол-во"
        value={qty}
        onChange={e => setQty(e.target.value)}
        fullWidth
      />
      <Typography>{unit}</Typography>
    </Stack>
  )
}

export const IconActionLink = (props: { act: Action; materialId: number }) => {
  const { act, materialId } = props
  return (
    <Link to={`${act.href}?material_id=${materialId}`}>
      <Button variant="outlined" startIcon={<act.icon />}>
        {act.name}
      </Button>
    </Link>
  )
}

export function CategoryInput(props: {
  defaultValue?: string
  onChange?: (value: string) => void
}) {
  const { data: categories } = useGetAllMaterialCategoriesQuery()

  if (!categories) {
    return <></>
  }

  const options = categories?.metal_pdo_materials.filter(e => e.category).map(e => e.category) || []

  return (
    <Autocomplete
      onChange={(event, value) => {
        console.log(value)
        if (props.onChange) {
          props.onChange(value!)
        }
      }}
      options={options as any[]}
      defaultValue={props.defaultValue || ''}
      disablePortal
      getOptionLabel={option => option || ''}
      freeSolo
      renderInput={params => <TextField {...params} label="Категория" />}
    />
  )
}
