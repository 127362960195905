import { MetalFlowSys } from 'src/lib/routes'
import { RouteConfig } from 'src/types/global'
import { actions } from './actions'
import { AddMaterial } from './material/add'
import { UpdateMaterial } from './material/update'
import { MaterialsView } from './material/view'
import { Nav } from './nav'
import { AddSuply } from './supply'
import { AddWriteOff } from './writeoff'

export default [
  {
    element: <Nav actions={actions} />,
    path: MetalFlowSys.nav
  },
  {
    element: <MaterialsView />,
    path: MetalFlowSys.materials
  },
  {
    element: <AddMaterial />,
    path: MetalFlowSys.material_add
  },
  {
    element: <UpdateMaterial />,
    path: MetalFlowSys.material_update
  },
  {
    element: <AddSuply />,
    path: MetalFlowSys.supply_add
  },
  {
    element: <AddWriteOff />,
    path: MetalFlowSys.writeoff_add
  }
] as RouteConfig[]
