import { Icon, UilListUl, UilMinusCircle, UilPlusCircle } from '@iconscout/react-unicons'
import { MetalFlowSys } from 'src/lib/routes'

export type Action = {
  name: string
  href: string
  icon: Icon
}

export const actions: Action[] = [
  {
    name: 'Добавить приход',
    href: MetalFlowSys.supply_add,
    icon: UilPlusCircle
  },
  {
    name: 'Добавить расход',
    href: MetalFlowSys.writeoff_add,
    icon: UilMinusCircle
  },
  {
    name: 'Показать материалы',
    href: MetalFlowSys.materials,
    icon: UilListUl
  }
]

export const WriteoffAction = actions[0]
export const SupplyAddAction = actions[1]
