import { Button, SelectChangeEvent, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { MetalFlowSys } from 'src/lib/routes'
import { useInsertMaterialMutation } from 'src/types/graphql-shema'
import { CategoryInput, GenericForm, MySelect, units } from '../shared'

export function AddMaterial() {
  const [unit, setUnit] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [category, setCategory] = useState<string>('')

  const [mut, { data, loading, error, reset }] = useInsertMaterialMutation()

  const handleSave = () => {
    mut({
      variables: {
        object: {
          name,
          unit,
          category
        }
      }
    })
  }

  const result = data?.insert_metal_pdo_materials_one?.id
  if (result) {
    setTimeout(() => {
      reset()
      setName('')
    }, 5000)
  }

  return (
    <GenericForm header="Добавить материал" goBackUrl={MetalFlowSys.materials}>
      <TextField
        label="Название материала"
        onChange={(event: any) => {
          setName(event.target.value)
        }}
        value={name}
        autoComplete={'off'}
      />

      <CategoryInput onChange={setCategory} />

      <MySelect
        label="Единицы измерения"
        onChange={(event: SelectChangeEvent) => {
          setUnit(event.target.value)
        }}
        value={unit}
        selectElements={units.map(e => ({ name: e, value: e }))}
      />

      {error && <Typography color={'red'}>{error.message}</Typography>}
      {result && <Typography>Успешно добавлено</Typography>}
      <Button
        variant="contained"
        onClick={handleSave}
        disabled={loading || !name || !unit}
        sx={{ mt: 2 }}
      >
        Save
      </Button>
    </GenericForm>
  )
}
