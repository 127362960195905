/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { UilEdit } from '@iconscout/react-unicons'
import { Button, Container, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { MetalFlowSys } from 'src/lib/routes'
import { useGetMaterialsQuery } from 'src/types/graphql-shema'
import { GoBackBtn, SectionTitle } from '../shared'

export function MaterialsView() {
  const { loading, data, error } = useGetMaterialsQuery({ fetchPolicy: 'network-only' })
  const navigate = useNavigate()

  const styles = css`
    td,
    th {
      padding: 10px;
      border-bottom: 1px dashed grey;
      text-align: left;
    }

    @media (max-width: 600px) {
      td,
      th {
        padding: 10px 2px;
      }
    }
  `

  if (loading) {
    return <Typography>Loading...</Typography>
  }
  if (error) {
    return <Typography>Error: {error.message}</Typography>
  }

  const handleEdit = (id: number) => {
    navigate(MetalFlowSys.material_update.replace(':id', id.toString()))
  }

  if (data) {
    return (
      <Container>
        <Stack>
          <Stack py={2} direction="row" alignItems={'center'} gap={1}>
            <GoBackBtn to={MetalFlowSys.nav} />
            <SectionTitle />
            <Button
              sx={{ ml: 'auto' }}
              variant="contained"
              onClick={() => navigate(MetalFlowSys.material_add)}
            >
              Новый материал
            </Button>
          </Stack>

          <table css={styles}>
            <thead>
              <tr>
                <th>ID</th>
                <th>Название</th>
                <th>Остаток</th>
                <th>Ед. изм</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.metal_pdo_materials.map(each => (
                <tr key={each.id} onDoubleClick={() => handleEdit(each.id)}>
                  <td>{each.id}</td>
                  <td>{each.name}</td>
                  <td>{each.stock?.current_qty}</td>
                  <td>{each.unit}</td>
                  <td onClick={() => handleEdit(each.id)} style={{ padding: '3px' }}>
                    <Button variant="iconic" size="small" color="secondary">
                      <UilEdit width={16} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Stack>
      </Container>
    )
  }

  return <></>
}
