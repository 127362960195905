// Define route paths using an enum for consistency
export enum AppRoutes {
  help = '/help',
  orders_production = '/orders/production',
  orders_registration = '/orders/registration',
  orders_recently = '/orders/recently',
  orders_archive = '/orders/archive',
  orders_report = '/orders/report',
  order_detail = '/orders/:id', // Updated to include the dynamic :id parameter
  settings = '/settings',
  reclamation = '/reclamation',
  attendance = '/attendance'
}

export enum MetalFlowSys {
  nav = `/metalflow/nav`,
  materials = `/metalflow/materials`,
  material_add = `/metalflow/material/add`,
  material_update = `/metalflow/material/update/:id`,
  supply_add = `/metalflow/supply/add`,
  writeoff_add = `/metalflow/writeoff/add`
}
