import { Autocomplete, Button, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { MetalFlowSys } from 'src/lib/routes'
import { useInsertMaterialWriteoffMutation } from 'src/types/graphql-shema'
import { GenericForm, Material, MaterialSelect, QtyInputWithUnit } from './shared'

const WRITEOFF_REASONS = ['производство', 'брак', 'продажа', 'прочее']

export function AddWriteOff() {
  const [qty, setQty] = useState<string>('')
  const [reason, setReason] = useState<string>(WRITEOFF_REASONS[0])
  const [material, setMaterial] = useState<Material>()

  const [mutate, { data, loading, error, reset }] = useInsertMaterialWriteoffMutation()

  const handleSubmit = async () => {
    if (!material?.id || !qty || !reason) return

    await mutate({
      variables: {
        object: {
          material_id: material.id,
          qty,
          writeoff_at: new Date(),
          reason
        }
      }
    })

    setQty('')
    setTimeout(reset, 5000)
  }

  return (
    <GenericForm header="Добавление расхода" goBackUrl={MetalFlowSys.materials}>
      <MaterialSelect setMaterial={setMaterial} material={material} />
      <QtyInputWithUnit unit={material?.unit} value={qty} setValue={setQty} />

      <Autocomplete
        freeSolo
        options={WRITEOFF_REASONS}
        value={reason}
        onChange={(_, newValue) => setReason(newValue || '')}
        renderInput={params => <TextField {...params} label="Причина списания" />}
      />

      {error && <Typography color="error">{error.message}</Typography>}
      {data?.insert_metal_pdo_writeoffs_one?.id && (
        <Typography color="success.main">Списание оформлено</Typography>
      )}

      <Button
        variant="contained"
        onClick={handleSubmit}
        disabled={loading || !material || !qty || !reason}
      >
        Сохранить
      </Button>
    </GenericForm>
  )
}
