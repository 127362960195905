import { Button, Typography } from '@mui/material'
import { useState } from 'react'
import { MetalFlowSys } from 'src/lib/routes'
import { useInsertMaterialSupplyMutation } from 'src/types/graphql-shema'
import { GenericForm, Material, MaterialSelect, QtyInputWithUnit } from './shared'

export function AddSuply() {
  const [qty, setQty] = useState<string>('')
  const [material, setMaterial] = useState<Material>()

  const [mut, { data, loading, error, reset }] = useInsertMaterialSupplyMutation()

  const save = async () => {
    await mut({
      variables: {
        object: {
          material_id: material?.id,
          qty,
          supplied_at: new Date(),
          supplier_name: ''
        }
      }
    })
    setQty('')
    setTimeout(reset, 5000)
  }

  const btnDisabled = !qty
  return (
    <GenericForm header="Добавление прихода" goBackUrl={MetalFlowSys.materials}>
      <MaterialSelect setMaterial={setMaterial} material={material} />
      <QtyInputWithUnit unit={material?.unit} value={qty} setValue={setQty} />

      {error && <Typography color={'red'}>{error.message}</Typography>}
      {data?.insert_metal_pdo_supplies_one?.id && (
        <Typography color="success.main">Приход зафиксирован</Typography>
      )}
      <Button variant="contained" onClick={save} disabled={btnDisabled}>
        Save
      </Button>
    </GenericForm>
  )
}
