import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MetalFlowSys } from 'src/lib/routes'
import {
  useDeleteMaterialMutation,
  useGetMaterialByPkQuery,
  useUpdateMaterialMutation
} from 'src/types/graphql-shema'
import { SupplyAddAction, WriteoffAction } from '../actions'
import { CategoryInput, GenericForm, IconActionLink, units } from '../shared'

export function UpdateMaterial() {
  const queryParams = useParams<{ id: string }>()
  const id = parseInt(queryParams.id || '')

  const [unit, setUnit] = useState<string>()
  const [name, setName] = useState<string>()
  const [category, setCategory] = useState<string>('')
  const [ready, setReady] = useState<boolean>(false)

  useGetMaterialByPkQuery({
    variables: {
      id
    },
    onCompleted(data) {
      setName(data.metal_pdo_materials_by_pk?.name)
      setUnit(data.metal_pdo_materials_by_pk?.unit)
      setCategory(data.metal_pdo_materials_by_pk?.category || '')
      setReady(true)
    }
  })

  const [mut, { data, loading, error }] = useUpdateMaterialMutation()

  const handleSave = () => {
    mut({
      variables: {
        id,
        _set: {
          name,
          unit
        }
      }
    })
  }

  if (ready) {
    return (
      <GenericForm
        header="Изменить материал"
        goBackUrl={MetalFlowSys.materials}
        beforeFormComp={
          <Stack direction="row" gap={1} mb={1} p={1}>
            <IconActionLink act={WriteoffAction} materialId={id} />
            <IconActionLink act={SupplyAddAction} materialId={id} />
          </Stack>
        }
      >
        <TextField
          label="Название материала"
          onChange={(event: any) => {
            setName(event.target.value)
          }}
          autoComplete={'off'}
          inputProps={{
            defaultValue: name
          }}
        />

        <CategoryInput defaultValue={category} onChange={setCategory} />

        <FormControl>
          <InputLabel>Единицы измерения</InputLabel>
          <Select
            defaultValue={unit}
            onChange={(event: SelectChangeEvent) => {
              setUnit(event.target.value)
            }}
            label="Единицы измерения"
          >
            {units.map(unit => (
              <MenuItem key={unit} value={unit}>
                {unit}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {error && <Typography color={'red'}>{error.message}</Typography>}
        {data?.update_metal_pdo_materials_by_pk && <Typography>Сохранено</Typography>}
        <Button variant="contained" sx={{ mt: 2 }} onClick={handleSave} disabled={loading}>
          Save
        </Button>

        <DeleteMaterial id={id} />
      </GenericForm>
    )
  } else return <CircularProgress />
}

function DeleteMaterial(props: { id: number }) {
  const [mut, { loading, data, error }] = useDeleteMaterialMutation({
    variables: {
      id: props.id
    }
  })

  const navigate = useNavigate()
  const handle = async () => {
    const res = await mut()
    if (res.data?.delete_metal_pdo_materials_by_pk?.id) {
      navigate(MetalFlowSys.materials)
    } else {
      alert(res.errors)
    }
  }

  return (
    <Button
      variant="outlined"
      color="error"
      sx={{ width: 'max-content' }}
      onClick={handle}
      disabled={loading}
    >
      Удалить
    </Button>
  )
}
