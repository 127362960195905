import { Icon, UilHome } from '@iconscout/react-unicons'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'

export interface Props {
  icon: Icon
  title: string
  p: number
}

export function PageTitle(props: Props) {
  const navigate = useNavigate()

  return (
    <Stack direction="row" sx={{ gap: 2, p: props.p, alignItems: 'center' }}>
      <Box display="flex" gap={1} sx={{ color: 'var(--accent)' }}>
        <props.icon />
        <Typography color={'var(--accent)'} fontWeight={600}>
          {props.title}
        </Typography>
      </Box>

      {/* opne mobile navigation menu */}
      <Link to="/help" style={{ marginLeft: 'auto' }}>
        <IconButton
          size="small"
          sx={{
            display: {
              xs: 'flex',
              sm: 'none'
            }
          }}
        >
          <UilHome />
        </IconButton>
      </Link>
    </Stack>
  )
}
