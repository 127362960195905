import { UilCalculatorAlt } from '@iconscout/react-unicons'
import { Container, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { PageTitle } from 'src/components/PageTitle'
import { Action } from './actions'

interface Props {
  actions: Action[]
}

export function Nav(props: Props) {
  return (
    <Container maxWidth="xs">
      <PageTitle title="ПДО" icon={UilCalculatorAlt} p={2}></PageTitle>

      <Stack
        p={2}
        gap={4}
        sx={{
          bgcolor: 'var(--L1)',
          borderRadius: '10px',
          border: '1px solid var(--L2)'
        }}
      >
        {props.actions.map(each => (
          <Link to={each.href} key={each.href}>
            <Stack direction="row" gap={1}>
              <each.icon />
              <Typography>{each.name}</Typography>
            </Stack>
          </Link>
        ))}
      </Stack>
    </Container>
  )
}
